import React, { Component } from 'react';
import { Modal } from 'rsuite';
import { Image, Loader, Icon, Form, Button as ButtonS } from 'semantic-ui-react';
import { Button, Row, Col } from 'reactstrap';

import Parse from 'widget/parse';
import PreloadFile from './PreloadFile';

class ModalUploadFiles extends Component {
  state = {
    loading: false,
    show: false,
    files: [],
    fileOptId: '',
    show2: false,
  };

  componentDidMount = () => {
    this.handleFetch();
  };

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { fileOpt = [] } = props;
    if (fileOpt && fileOpt.length) {
      const fileOptId = fileOpt[0].value;
      this.setState({ fileOptId });
    }
  };

  handleOpen = () => {
    if (this.props.dbName && this.props.fieldName) {
      this.setState({ show2: true });
    } else {
      this.setState({ show: true });
    }
  };

  handleClose = () => {
    this.setState({
      files: [],
      show2: false,
      show: false,
    });
  };

  handleUploadImg = async () => {
    const {
      companyObj,
      rowData = {},
      handleData,
      // pageObj = {},
      // group,
      // handleFetch,
    } = this.props;
    const { files } = this.state;
    this.setState({ loading: true });

    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend('webimage');
      const newDoc = new db();
      promises.push(newDoc.save());
    }

    const docArr = await Promise.all(promises).catch(error =>
      console.log('error', error),
    );
    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        albumId: rowData.objectId || '',
        iscover: false,
        // pageId: pageObj.objectId || '',
        // componentId: pageObj.name || '',
        // navId: pageObj.nav1Id || '',
        name: file.name || '',
        // group,
      };
      promises2.push(Parse.saveData('webimage', element));

      promises2.push(
        Parse.saveImgThumb(file, 'webimage', doc.id, 'img1File', 'img1File2'),
      );
    }

    await Promise.all(promises2).catch(error => console.log('error', error));
    this.setState({ loading: false });
    handleData(rowData);
    this.handleClose();
    // handleFetch();
  };

  handleUploadFile = async () => {
    const { companyObj, rowData, handleData } = this.props;
    const { files, fileOptId } = this.state;
    this.setState({ loading: true });

    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend('fileCenter2');
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error =>
      console.log('error', error),
    );

    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        fileCenter1Id: rowData.objectId,
        fileCenter1Text: rowData.name,
        name: file.name || '',
      };
      promises2.push(
        Parse.saveData('fileCenter2', element).then(async () => {
          await Parse.saveFile(file, '1', doc.id, 'fileCenter2', fileOptId);
        }),
      );
    }
    await Promise.all(promises2).catch(error => console.log('error', error));

    this.setState({ loading: false });
    handleData();
    this.handleClose();
  };

  handleCustomUploadFile = async () => {
    const {
      companyObj,
      handleData,
      dbName,
      fieldName,
      condition = {},
      emailsId = '',
      subject = '',
    } = this.props;
    const { files } = this.state;
    this.setState({ loading: true });
    console.log("emailsId:", emailsId)
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend(dbName);
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error =>
      console.log('error', error),
    );
    if (emailsId !== '' && emailsId !== 'noselect') { //原始//在已有的樣板上新增附件
      const promises2 = [];
      for (let i = 0; i < docArr.length; i++) {
        const file = files[i];
        const doc = docArr[i];
        const element = {
          ...condition,
          objectId: doc.id,
          id: doc.id,
          _id: doc.id,
          companyId: companyObj.objectId,
          companyName: companyObj.name,
          name: file.name || '',
          emailsId,
          subject
        };
        promises2.push(
          Parse.saveData(dbName, element).then(async () => {
            await Parse.saveFile(file, '1', doc.id, dbName, fieldName);
          }),
        );

      }
      // if (emailsId !== '' || emailsId !== 'noselect') {
      await Promise.all(promises2).catch(error => console.log('error', error));
      this.setState({ loading: false });
      handleData();
      this.handleClose();
    } else { //新的樣板的新增附件
      const promises2 = [];
      for (let i = 0; i < docArr.length; i++) {
        const file = files[i];
        const doc = docArr[i];
        const element = {
          ...condition,
          objectId: doc.id,
          id: doc.id,
          _id: doc.id,
          companyId: companyObj.objectId,
          companyName: companyObj.name,
          name: file.name || '',
          type: 'newAttatchment',
          subject,
        };
        promises2.push(
          Parse.saveData(dbName, element).then(async () => {
            await Parse.saveFile(file, '1', doc.id, dbName, fieldName);
          }),
        );

      }
      await Promise.all(promises2).catch(error => console.log('error', error));
      this.setState({ loading: false });
      handleData(this.props, 3);
      this.handleClose();
    }
  };

  handleUploadAnnounceFile = async () => {
    const { rowData, AnnounceCenterObj, handleData } = this.props;
    const { files } = this.state;
    this.setState({ loading: true });
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend('webimage');
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error =>
      console.log('error', error),
    );

    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: AnnounceCenterObj.companyObj.objectId,
        companyName: AnnounceCenterObj.companyObj.name,
        pageId: rowData.objectId,
        name: file.name || '',
        type: 'announce',
      };
      promises2.push(
        Parse.saveData('webimage', element).then(async () => {
          await Parse.saveFile(file, '1', doc.id, 'webimage', 'file1');
        }),
      );
    }
    await Promise.all(promises2).catch(error => console.log('error', error));

    this.setState({ loading: false });
    handleData();
    this.handleClose();
  };

  handleChangeFileOpt = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleChangeFile = files => {
    this.setState({ files });
  };

  render() {
    // const { rowData = {}, isImage, fileOpt = [], } = this.props;
    // const { loading, show, fileOptId, files, show2 } = this.state;
    const { rowData = {}, isImage, fileOpt = [], isAnnounce, isEdit = true, buttonType } = this.props;
    const { loading, show, fileOptId, files, show2 } = this.state;

    return (<>
      {buttonType === '1' ? <ButtonS icon="upload" content="上傳相片" basic color='black' onClick={() => this.handleOpen()} /> : null}
      {!buttonType ? <Button
        style={{ backgroundColor: '#51cbce', borderColor: '#51cbce', color: '#FFFFFF', opacity: 1, }}
        color="primary"
        disabled={!isEdit}
        onClick={this.handleOpen}
      >
        <i className="fa fa-plus" /> 新增{isImage === '1' ? '照片' : '檔案'}
      </Button> : null}
      <Modal open={show} onClose={this.handleClose} size="lg"   >
        <Modal.Header>
          <Modal.Title>
            新增 {rowData.name} {isImage === '1' ? '照片' : '檔案'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: '20px' }}>
            {fileOpt.length ? (
              <Row>
                <Col md={6}>
                  <Form.Select
                    label="上傳欄位"
                    style={{ marginRight: '5px' }}
                    options={fileOpt}
                    onChange={this.handleChangeFileOpt}
                    name="fileOptId"
                    value={fileOptId}
                  />
                </Col>
              </Row>
            ) : null}
            <br />
            <PreloadFile multiple isImage={isImage} handleChangeFile={this.handleChangeFile} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isAnnounce === '1' ? (<>
            <Button
              color="success"
              onClick={this.handleUploadAnnounceFile}
              disabled={!files.length}
            >
              {!loading ? (<>
                <i className="fa fa-upload" /> 上傳
              </>) : (<Loader active inverted inline size="small" />)}
            </Button>{' '}
          </>) : (<>
            <Button
              color="success"
              onClick={isImage === '1' ? this.handleUploadImg : this.handleUploadFile}
              disabled={!files.length}
            >
              {!loading ? (<>
                <i className="fa fa-upload" /> 上傳
              </>) : (<Loader active inverted inline size="small" />)}
            </Button>{' '}
          </>)}
          <Button icon="x" onClick={this.handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal open={show2} onClose={this.handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>
            新增 {rowData.name} {isImage === '1' ? '相簿照片' : '檔案'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PreloadFile
            multiple
            isImage={isImage}
            handleChangeFile={this.handleChangeFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="success"
            onClick={isImage === '1' ? this.handleUploadImg : this.handleCustomUploadFile}
            disabled={!files.length}
          >
            {!loading ? (<>
              <i className="fa fa-upload" /> 上傳
            </>) : (<Loader active inverted inline size="small" />)}
          </Button>{' '}
          <Button icon="x" onClick={this.handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default ModalUploadFiles;
